import React from 'react';
import Helmet from 'react-helmet';

import Layout from '../../components/layout';
import ReadyToWork from '../../components/readyToWork';
import CareerSVG from '../../components/backgroundSVGS/CareerSVG';

// import { withPrefix } from 'gatsby'

// console.log('To apply, send your C.V. to howdy@builtbyelement.io');


const HiringPage = () => (
    <Layout>
        <Helmet
            title="We're hiring! | Element Studio - Cheltenham Web Design &amp; Development"
            meta={[
                {
                    name: 'description',
                    content: 'Element Studio offer web design and development, as well as ongoing support and other services.'
                }
            ]}
        />

        <header className="hero">
            <div className="hero__inner">
                <div className="hero-content container-medium">
                    <h1 class="type-backdrop">
                        <CareerSVG />
                    </h1>
                    <span className="type-eyebrow text-label--grey">We're hiring a</span>
                    <h2 className="type-title type-uppercase">Senior Backend Developer</h2>
                </div>
            </div>
            <svg className="signpost-center" viewBox="0 0 8 74" width="8" height="74">
                <use xlinkHref="#icon-arrow-down" />
            </svg>
        </header>


        <section className="wrapper-chunky">
            <div className="container-narrow">
                <p className="type-intro">
                    We're looking for a sh*t hot senior backend developer specialising in PHP &amp; Laravel (or anything servery/serverless)<span className="type-highlight">.</span>
                </p>
                <hr className="sep-thin-light sep-hiring" />
                <ul className="basic_list benefits">
                    <li className="hinge breathe--small">
                        <svg viewBox="0 0 32 32" width="32" height="32" className="benefits__icon hinge__fixed icon-red">
                            <use xlinkHref="#icon-circle-check"></use>
                        </svg>
                        <span className="hinge__fluid type-label">45 - 65k per annum</span>
                    </li>
                    <li className="hinge breathe--small">
                        <svg viewBox="0 0 32 32" width="32" height="32" className="hinge__fixed benefits__icon icon-red">
                            <use xlinkHref="#icon-circle-check"></use>
                        </svg>
                        <span className="hinge__fluid type-label">Remote working available</span>
                    </li>
                    <li className="hinge breathe--small">
                        <svg viewBox="0 0 32 32" width="32" height="32" className="hinge__fixed benefits__icon icon-red">
                            <use xlinkHref="#icon-circle-check"></use>
                        </svg>
                        <span className="hinge__fluid type-label">&pound;250 learning budget per annum</span>
                    </li>
                    <li className="hinge breathe--small">
                        <svg viewBox="0 0 32 32" width="32" height="32" className="hinge__fixed benefits__icon icon-red">
                            <use xlinkHref="#icon-circle-check"></use>
                        </svg>
                        <span className="hinge__fluid type-label">Flexible working hours</span>
                    </li>
                    <li className="hinge breathe--small">
                        <svg viewBox="0 0 32 32" width="32" height="32" className="hinge__fixed benefits__icon icon-red">
                            <use xlinkHref="#icon-circle-check"></use>
                        </svg>
                        <span className="hinge__fluid type-label">21 days paid leave + Christmas Eve to New Year + plus your birthday off</span>
                    </li>
                </ul>

                <hr className="sep-thin-light sep-hiring" />

                <p className="type-subtitle island-small">
                    We are a small team of developers and designers, based in Cheltenham, UK who build APIs, business tools, marketing sites and SPAs.
                </p>

                <p className="type-subtitle island-small">
                    We are looking for a senior backend developer to help meet increasing demand for a growing API for a leading UK company as well as oversee internal best practice and development process.
                </p>

                <p className="type-body">
                    When applying for the Senior Backend Developer role you are expected to have a number of years experience working on commercial projects, a strong skillset and the ability to work with other developers to improve studio practices and outcomes.
                </p>
                <p className="type-body">
                    Your duties will include:
                </p>
                <ul>
                    <li>Developing API's for consumption and interaction via first party React applications or third party systems</li>
                    <li>Managing continual integration systems, tests and overall code quality</li>
                    <li>Building a complete solution from provided specifications using the required technologies</li>
                    <li>Improving internal process, code libraries and best practices</li>
                </ul>

                <p className="type-body">
                    You will be working in tandem with other developers to provide the required application functionality as well as provide estimated timescales and recommend solutions for proposed functionality.
                </p>

                <p className="type-body">
                    As a senior member of staff you will provide assistance to junior/middleweight staff members and be active in the creation of specified solutions / responses to briefs.
                </p>

                <p className="type-body">
                    To apply, send your C.V. to <a href="mailto:howdy@builtbyelement.io" className="type-textlink" title="Email: howdy@builtbyelement.io">
                    howdy@builtbyelement.io</a>.
                </p>

                <hr className="sep-thin-light sep-hiring" />


            </div>
            <div className="container-medium">

                <p className="type-intro">
                    Skills for the bills<span className="type-highlight">:</span>
                </p>

                <div className="grid-x grid-margin-x small-only-rhythm">
                    <div className="cell medium-6">

                        <h3>Key:</h3>

                        <ul className="skills">
                            <li className="hinge breathe--small">
                                <svg viewBox="0 0 21 21" width="32" height="32" className="icon-red hinge__fixed skills__icon">
                                    <use xlinkHref="#icon-cog"></use>
                                </svg>
                                <span className="hinge__fluid type-label">PHP/Laravel or other open-source backend technology (expert)</span>
                            </li>
                            <li className="hinge breathe--small">
                                <svg viewBox="0 0 21 21" width="32" height="32" className="icon-red hinge__fixed skills__icon">
                                    <use xlinkHref="#icon-cog"></use>
                                </svg>
                                <span className="hinge__fluid type-label">Testing library / Unit testing experience</span>
                            </li>
                            <li className="hinge breathe--small">
                                <svg viewBox="0 0 21 21" width="32" height="32" className="icon-red hinge__fixed skills__icon">
                                    <use xlinkHref="#icon-cog"></use>
                                </svg>
                                <span className="hinge__fluid type-label">Git source control</span>
                            </li>
                            <li className="hinge breathe--small">
                                <svg viewBox="0 0 21 21" width="32" height="32" className="icon-red hinge__fixed skills__icon">
                                    <use xlinkHref="#icon-cog"></use>
                                </svg>
                                <span className="hinge__fluid type-label">Command line experience</span>
                            </li>
                            <li className="hinge breathe--small">
                                <svg viewBox="0 0 21 21" width="32" height="32" className="icon-red hinge__fixed skills__icon">
                                    <use xlinkHref="#icon-cog"></use>
                                </svg>
                                <span className="hinge__fluid type-label">Must have watched Jurassic Park 🦖</span>
                            </li>
                        </ul>
                    </div>
                    <div className="cell medium-6">

                        <h3>Desirable:</h3>

                        <ul className="skills">
                            <li className="hinge breathe--small">
                                <svg viewBox="0 0 21 21" width="32" height="32" className="icon-red hinge__fixed skills__icon">
                                    <use xlinkHref="#icon-cog"></use>
                                </svg>
                                <span className="hinge__fluid type-label">Continual integration system experience</span>
                            </li>
                            <li className="hinge breathe--small">
                                <svg viewBox="0 0 21 21" width="32" height="32" className="icon-red hinge__fixed skills__icon">
                                    <use xlinkHref="#icon-cog"></use>
                                </svg>
                                <span className="hinge__fluid type-label">Experience with Jira bug tracker</span>
                            </li>
                            <li className="hinge breathe--small">
                                <svg viewBox="0 0 21 21" width="32" height="32" className="icon-red hinge__fixed skills__icon">
                                    <use xlinkHref="#icon-cog"></use>
                                </svg>
                                <span className="hinge__fluid type-label">React/Javascript/Typescript experience</span>
                            </li>
                            <li className="hinge breathe--small">
                                <svg viewBox="0 0 21 21" width="32" height="32" className="icon-red hinge__fixed skills__icon">
                                    <use xlinkHref="#icon-cog"></use>
                                </svg>
                                <span className="hinge__fluid type-label">PHP (If expert in other open-source backend technologies)</span>
                            </li>
                            <li className="hinge breathe--small">
                                <svg viewBox="0 0 21 21" width="32" height="32" className="icon-red hinge__fixed skills__icon">
                                    <use xlinkHref="#icon-cog"></use>
                                </svg>
                                <span className="hinge__fluid type-label">AWS or alike management experience</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

        </section>
        <ReadyToWork />
    </Layout>
);

export default HiringPage;
